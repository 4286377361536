import * as React from "react";
import {useEffect, useLayoutEffect, useRef} from "react";
import {createRefAndSetFunction} from "./Helpers/RefHelper";

interface ScaleTextProps {
    children: any,
    link?: string,
    additionalClassName?: string,
}

const ScaleText = ({children, link, additionalClassName}: ScaleTextProps) => {
    const containerRef = useRef(null);
    const textRef = useRef(null);
    const [initialSize, setInitialSize] = createRefAndSetFunction(null);
    const [timer, setTimer] = createRefAndSetFunction(null);
    const debug = false;


    const scaleText = () => {
        if (!initialSize.current || !containerRef.current || !textRef.current) return;
        const currentRect = {width: textRef.current.offsetWidth, height: textRef.current.offsetHeight} //initialSize.current;

        if (currentRect.width === 0) currentRect.width = initialSize.current.width;

        const targetRect = containerRef.current.getBoundingClientRect();
        const marginX = 5;

        const scaleX = Math.round(((targetRect.width-marginX) / currentRect.width) * 100) / 100;
        const scaleY = Math.round((targetRect.height / currentRect.height) * 100) / 100;

        textRef.current.style.transform = `scale(${scaleX}, ${scaleY})`

        if (debug) {
            console.log(targetRect.width, currentRect, scaleX, initialSize.current.width)
        }
    };

    useLayoutEffect(() => {
        if (!initialSize.current) {
            if (textRef.current) setInitialSize(textRef.current.getBoundingClientRect());
        }
    }, [])

    useEffect(() => {

        const onResize = () => {
            if (timer.current) clearTimeout(timer.current)
            setTimer(setTimeout(() => {
                if (timer.current) clearTimeout(timer.current)
                scaleText();
            }, 100))
        };

        window.addEventListener('resize', onResize);

        setTimeout(onResize, 100);

        // On component unmount
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);


    return (
        <div className={`c-scaletext--container ${additionalClassName||''}`} ref={containerRef}>
            {link ? (
                <a target={"_blank"} rel={"nofollow"} className={"c-scaletext--link"} href={link}>
                    <div className={`c-scaletext--text`} ref={textRef}>{children}</div>
                </a>
            ) : (
                <div className={`c-scaletext--text`} ref={textRef}>{children}</div>
            )}
        </div>
    )
}

export default ScaleText;
